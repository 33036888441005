import { useState } from 'react'
import {
    BooleanInput,
    Create,
    Datagrid,
    FunctionField,
    List,
    ListProps,
    SimpleForm,
    TextField,
    useTranslate
} from 'react-admin'
import { Record, useRefresh } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

import AvailableProductsSelect from 'components/formfields/AvailableProductsSelect'
import datagridStyles from 'layout/styles/datagridStyles'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import { usePackageAvailableProductsQuery } from 'apollo/package_manager/queries/PackageAvailableProducts.generated'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import RelatedResourceToolbar from 'components/common/RelatedResourceToolbar'

export interface AvailableProduct {
    id: string
    sku: string
    name: string
}

const usePackageProductStyles = makeStyles({
    list: {
        width: '100%',
        margin: '2rem 0'
    },
    form: {
        width: 900
    }
})

interface PackageProductListProps extends ListProps {
    isPackage?: boolean
}

const PackageProductList = (props: PackageProductListProps) => {
    const RESOURCE = 'PackageProduct'
    const [open, setOpen] = useState(false)
    const reload = useRefresh()
    const translate = useTranslate()
    const classes = usePackageProductStyles()
    const datagridClasses = datagridStyles()
    const packageId = props?.filter?.package_id

    const { data: { packagesAvailableProducts: availableProducts } = {} } =
        usePackageAvailableProductsQuery()
    const mappedAvailableProducts =
        availableProducts &&
        availableProducts.map((item) => ({
            sku: item?.sku ?? '',
            name: item?.name ?? ''
        }))

    /*
     * Toggle modal state
     */
    const toggleModal = (): void => {
        setOpen(!open)
    }

    /*
     * Closes modal and re-renders after saving
     */
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    /*
     * Gets product name by sku from list of available products
     */
    const getProductName = (sku: string): string => {
        return (
            (mappedAvailableProducts &&
                mappedAvailableProducts.find((availableProduct) => availableProduct?.sku === sku)
                    ?.name) ||
            sku
        )
    }

    return (
        <>
            <ListWrapper>
                <List
                    basePath={`/${RESOURCE}`}
                    resource={`${RESOURCE}`}
                    actions={
                        <RelatedResourceToolbar
                            resource={RESOURCE}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    perPage={20}
                    className={classes.list}
                    bulkActionButtons={false}
                    sort={{ field: 'sku', order: 'ASC' }}
                    {...props}
                >
                    <Datagrid
                        body={<CustomDatagridBody />}
                        classes={datagridClasses}
                        rowClick="edit"
                    >
                        <TextField
                            label={translate('manager.resources.package.products.sku')}
                            source="sku"
                        />
                        <FunctionField
                            label={translate('manager.resources.package.products.name')}
                            render={(record?: Record | undefined) =>
                                getProductName(record?.sku || '')
                            }
                        />
                        <TextField
                            label={translate('manager.resources.package.pricing')}
                            source="pricing"
                            sortable={false}
                        />
                        <FunctionField
                            sortBy="active"
                            source="active"
                            label={translate('manager.resources.general.status')}
                            render={(record?: Record | undefined) => (
                                <DatagridPublishedColumnContent record={record} />
                            )}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <Dialog open={open} onClose={() => toggleModal()} maxWidth={false}>
                <Create
                    resource={RESOURCE}
                    basePath={`/${RESOURCE}`}
                    onSuccess={handleSuccess}
                    {...props}
                >
                    <SimpleForm className={classes.form} initialValues={{ package_id: packageId }}>
                        <AvailableProductsSelect isPackage={props.isPackage} required />
                        <BooleanInput
                            row
                            source="is_main"
                            label={translate(`manager.resources.${RESOURCE}.is_main`)}
                        />
                        <BooleanInput
                            row
                            source="is_optional"
                            label={translate(`manager.resources.${RESOURCE}.is_optional`)}
                        />
                        <BooleanInput
                            row
                            source="is_included"
                            label={translate(`manager.resources.${RESOURCE}.is_included`)}
                        />
                        <BooleanInput
                            row
                            source="active"
                            label={translate('manager.resources.general.published')}
                        />
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

export default PackageProductList
